<template>
  <!--  下面的任务tab-->
  <div style="height: 100vh; overflow: hidden;;box-sizing: border-box;padding: 10px 20px;" class="task">
    <el-tabs style="height: 100%;" v-model="tabsName">
      <div style="position: absolute;right: 0;top: -50px;height: 10px;z-index: 2000;">
        <el-radio-group v-model="viewstyle" size="mini">
          <el-radio-button label="card" :border="false"><i class="el-icon-menu"
              style="font-size: 16px"></i></el-radio-button>
          <el-radio-button label="list" :border="false"><i class="el-icon-s-operation"
              style="font-size: 16px;font-weight: bold"></i></el-radio-button>
        </el-radio-group>
      </div>

      <el-tab-pane :label="tabKeyName" name="1" style="position: relative;height: 100%;">
        <div class="Project">
          <!-- 卡片 -->
          <div v-loading="loading" class="projectContent" v-if="viewstyle === 'card'">
            <div class="AllBox">
              <el-card :body-style="{ padding: '0px' }" class="proAddCard" shadow="hover" @click.native="handleAdd()"
                v-if="this.queryParam.ClassType != 1">
                <el-image :src="getProjectImg(null)" class="proAddImg" fit="cover"></el-image>
                <div>
                  <span class="proAddTitle">点击新建任务</span>
                </div>
              </el-card>
              <el-card v-for="item in data" :key="item.Id" :body-style="{ padding: '0px' }" class="proListCard"
                shadow="hover" @click.native="handleView(item)" :title="item.Name">
                <el-image :src="getProjectImg(item)" class="proListImg" fit="cover"></el-image>
                <div class="taskType">
                  <span>{{ item.AppyName }}</span>
                </div>
                <div v-if="item.CreatorId === vipuser.Id" class="delIcon" @click.stop="handleDel(item)">
                  <i class="el-icon-delete"></i>
                </div>
                <div class="taskStatus">
                  <span v-if="item.Status == 0">启动任务</span>
                  <span v-else-if="item.Status == 1">进行中</span>
                  <span v-else-if="item.Status == 2">已完成</span>
                  <span v-else>未知状态</span>
                </div>
                <div class="taskStatus2" v-if="item.TaskTypeName">
                  <span>{{ item.TaskTypeName }}</span>
                </div>
                <div style="padding: 10px">
                  <span class="taskTitle">{{ item.Name }}</span>
                </div>
                <div class="probottom clearfix">
                  <span class="footerleft" :title="item.CreatorRealName">{{
                    item.CreatorRealName
                  }}</span>&nbsp;&nbsp;
                  <span class="footerright">{{
                    item.CreateTime
                      ? timeDataFormat(item.CreateTime, "YYYY-MM-DD HH:mm")
                      : ""
                  }}</span>
                </div>
              </el-card>
            </div>
            <!-- 底部分页 -->
            <pagination :limit.sync="pagination.PageRows" :page.sync="pagination.PageIndex" :total="pagination.total"
              @pagination="currentChange" style="text-align: right;line-height: 40px;padding: 0;height: 40px;" />
          </div>

          <!-- //列表 -->
          <div v-loading="loading" class="projectContent" v-if="viewstyle === 'list'">
            <div class="AllBox">
              <div style="width: 100%">
                <div @click="handleAdd()" style="
                    position: sticky;
                    top: 0;
                    background: #fff;
                    z-index: 999;
                  " v-if="this.queryParam.ClassType != 1">
                  <div style="
                      display: flex;
                      align-items: center;
                      padding: 5px 0px;
                    " class="additem">
                    <div style="
                        width: 180px;
                        position: relative;
                        height: 80px;
                        margin-right: 20px;
                      ">
                      <el-image :src="getProjectImg(null)" class="list-img" fit="cover"></el-image>
                    </div>
                    <div
                      style="flex: auto;font-size: 36px;letter-spacing: 10px; font-weight: bold; text-align: center;padding:0;color: #9b9999">
                      点击新建任务</div>
                  </div>
                  <el-divider></el-divider>
                </div>
                <div v-for="(item, index) in data" :key="index" class="list-item">
                  <div style="
                      display: flex;
                      align-items: center;
                      padding: 5px 0px;
                      cursor: pointer;
                    " @click="handleView(item)">
                    <div style="
                        width: 180px;
                        position: relative;
                        height: 80px;
                        margin-right: 20px;
                      ">
                      <el-image :src="getProjectImg(item)" class="list-img" fit="cover"></el-image>
                      <div class="tabsType">
                        <span>{{ item.AppyName }}</span>
                      </div>
                      <div class="tabsStatus">
                        <span v-if="item.Status == 0">启动任务</span>
                        <span v-else-if="item.Status == 1">进行中</span>
                        <span v-else-if="item.Status == 2">已完成</span>
                        <span v-else>未知状态</span>
                      </div>
                      <div class="tabsStatus2" v-if="item.TaskTypeName">
                        <span>{{ item.TaskTypeName }}</span>
                      </div>
                    </div>
                    <div style="flex: auto">{{ item.Name }}</div>
                    <div style="width: 180px">{{ item.CreatorRealName }}</div>
                    <div style="width: 180px">
                      {{
                        item.ModifyDate
                          ? timeDataFormat(item.ModifyDate, "YYYY-MM-DD HH:mm")
                          : ""
                      }}
                    </div>
                    <div style="width: 80px">
                      <span @click.stop="handleDel(item)" style="cursor: pointer">
                        <el-tooltip content="删除" placement="top" :open-delay="450">
                          <i class="el-icon-delete" style="font-size: 20px; color: #000"></i>
                        </el-tooltip>
                      </span>
                    </div>
                  </div>
                  <el-divider></el-divider>
                </div>
              </div>
            </div>
            <!-- 底部分页 -->

            <pagination :limit.sync="pagination.PageRows" :page.sync="pagination.PageIndex" :total="pagination.total"
              @pagination="currentChange" style="text-align: right;line-height: 40px;padding: 0;height: 40px;" />

          </div>
          <el-dialog :visible.sync="addTaskVisible" title="新建任务" width="30%">
            <el-form ref="addTaskForm" :model="entity" :rules="rules" label-width="100px">
              <el-form-item label="项目" prop="ProjectId">
                <el-select v-model="entity.ProjectId" placeholder="请选择项目">
                  <el-option v-for="item in ProjectList" :key="item.Id" :label="item.ProjectName"
                    :value="item.ProjectId"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="应用" prop="AppyId">
                <el-select v-model="entity.AppyId" placeholder="请选择应用" @change="appyChange">
                  <el-option v-for="item in AppList" :key="item.Id" :label="item.ApplyName"
                    :value="item.Id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="任务类型" prop="TaskTypeKey" v-if="TaskTypeList.length > 0">
                <el-select v-model="entity.TaskTypeKey" placeholder="请选择任务类型">
                  <el-option v-for="item in TaskTypeList" :key="item.DicValue" :label="item.DicName"
                    :value="item.DicValue"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="任务名称" placeholder="请输入任务名称" prop="Name">
                <el-input v-model="entity.Name"></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button size="mini" @click="addTaskVisible = false">取 消</el-button>
              <el-button size="mini" type="primary" @click="addTaskClose">确 定</el-button>
            </span>
          </el-dialog>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapState } from "vuex";
import folderImg from "@/assets/folder.png";
import addfolderImg from "@/assets/Addfolder.png";

export default {
  data() {
    return {
      viewstyle: "card",
      tabsName: "1",
      tabKeyName: "全部任务",
      vipuser: {},
      entity: {},
      addTaskVisible: false,
      activeName: "TaskAll",
      loading: false,
      rules: {
        ProjectId: [
          { required: true, message: "请选择项目", trigger: "change" },
        ],
        AppyId: [{ required: true, message: "请选择应用", trigger: "change" }],
        Name: [{ required: true, message: "请输入任务名称", trigger: "blur" }],
        TaskTypeKey: [{ required: true, message: "请选择任务类型", trigger: "change" }],
      },
      data: [],
      ProjectList: [],
      AppList: [],
      tabList: [
        { name: "All", label: "全部任务" },
        { name: "second", label: "参与任务" },
        { name: "third", label: "我的任务" },
      ],
      pagination: {
        PageIndex: 1,
        PageRows: 20,
        total: 0,
        SortField: "Id",
        SortType: "desc",
        pageSizeOptions: [10, 30, 50],
      },
      queryParam: {},
      folderImg,
      addfolderImg,
      tabTypeListData: [],
      TaskTypeList: []
    };
  },
  computed: {
    ...mapState(["userInfo", "searchValue"]),
    // 绑定项目图片
    getProjectImg: () => {
      return (item) =>
        item ? (item.ProjectImg ? item.ProjectImg : folderImg) : addfolderImg;
    },
    getSearchResultHight() {
      return Number(document.body.offsetHeight - 190);
    },
  },
  mounted() {
    this.vipuser = this.userInfo;
    // 清除任务开启标识
    sessionStorage.removeItem("PatGoOpen");
    sessionStorage.removeItem("AttractTaskOpen");
    sessionStorage.removeItem("RGOpen");
    // 所有项目获取
    this.$http
      .post("/Base_ProjectWork/Base_ProjectPartake/GetProjectPartakeList", {
        Search: {
          UserId: this.userInfo.Id,
          ClassType: 0,
        },
        SortField: "Id",
        SortType: "desc",
      })
      .then((res) => {
        if (res.Success) {
          console.log("获取项目数据信息", res.Data)
          this.ProjectList = res.Data;
        }
      });
    // 所有应用获取
    this.$http
      .post("/Base_BasicData/Base_Apply/GetDataList", {})
      .then((res) => {
        if (res.Success) {
          this.AppList = res.Data;
        }
      });
  },
  watch: {
    $route: {
      handler(n) {
        this.$store.commit("setSearchValue", "");
        if (n.path.indexOf("TaskAll") > -1) {
          this.queryParam.ClassType = 0;
          this.tabKeyName = "全部任务";
        } else if (n.path.indexOf("Tasksecond") > -1) {
          this.queryParam.ClassType = 1;
          this.tabKeyName = "参与任务";
        } else if (n.path.indexOf("Taskthird") > -1) {
          this.tabKeyName = "我的任务";

          this.queryParam.ClassType = 2;
          this.queryParam.Conditions = [
            {
              condition: "Name",
              keyword: this.searchValue,
            },
          ];
        }

        this.activeName = n.params.type;

        this.handleClick();
      },
      immediate: true,
    },
    searchValue(value, n) {
      console.log(value, n);
      // console.log(this.activeName);
      this.queryParam.Conditions = [
        {
          condition: "Name",
          keyword: value,
        },
      ];
      this.handleClick();
    },
  },
  methods: {
    //获取任务字典类型
    getTaskTypeList(code) {
      this.$http
        .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
          Condition: "DicKey",
          Keyword: 'TaskType_' + code,
        }).then(res => {
          if (res.Success) {
            this.TaskTypeList = res.Data?.DictionaryDetailsList || []
          }
        })
    },
    // 新建任务保存
    addTaskClose() {
      this.$refs.addTaskForm.validate((valid) => {
        if (!valid) return;
        this.$http
          .post("/Base_TaskWork/Base_Task/SaveData", this.entity)
          .then((res) => {
            if (res.Success) {
              this.$message.success("操作成功");
              this.getDataList();
              this.addTaskVisible = false;
            } else this.$message.error(res.Msg);
          });
      });
    },
    // 应用选择
    appyChange(e) {
      let obj = this.AppList.find(
        (item) => item.Id == e
      )
      if (obj) {
        this.entity.AppyName = obj.ApplyName;
        this.$set(this.entity, 'TaskTypeKey', '')
        this.getTaskTypeList(obj.ApplyCode)
      }

    },
    // 新建任务
    handleAdd() {
      this.addTaskVisible = true;
      this.entity = { Status: 0 };
      this.TaskTypeList = []
      this.$nextTick(() => {
        if (this.$refs.addTaskForm) this.$refs.addTaskForm.clearValidate();
      })
    },
    // 任务删除
    handleDel(e) {
      this.$confirm("此操作将永久删除该任务, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("Base_TaskWork/Base_Task/TaskDeleteData", [e.Id])
            .then((res) => {
              if (res.Success) {
                this.getDataList(e.index);
                this.$message.success("操作成功");
              } else this.$message.error(res.Msg);
            });
        })
        .catch(() => {
        });
    },
    getDataList() {
      this.$http
        .post("/Base_TaskWork/Base_Task/GetDataListByMoreCondition", {
          PageIndex: this.pagination.PageIndex,
          PageRows: this.pagination.PageRows,
          SortField: this.pagination.SortField,
          SortType: this.pagination.SortType,
          Search: this.queryParam,
        })
        .then((res) => {
          if (res.Success) {
            this.data = res.Data;
            console.log('打印任务信息2024-1-13：', this.data)
            this.pagination.total = res.Total;
          }
        });
    },
    // 页签切换
    handleClick() {
      this.pagination = {
        PageIndex: 1,
        PageRows: 50,
        total: 0,
        SortField: "Id",
        SortType: "desc",
      };
      this.getDataList();
    },
    // 查看任务
    handleView(item) {
      console.log(item);
      let pId = item.ProjectId || item.Id;
      this.$router.push(
        "/Template?prop=view&id=" +
        pId +
        "&AppyId=" +
        item.AppyId +
        "&ApplyCode=" +
        item.ApplyCode +
        "&TaskId=" +
        item.Id +
        "&Status=" +
        item.Status + "&TaskTypeKey=" +
        item.TaskTypeKey || ''
      );
    },
    currentChange(data) {
      this.pagination.PageIndex = data.pageIndex;
      this.pagination.PageRows = data.pageSize;
      this.getDataList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-pagination .el-select .el-input .el-input__inner {
  padding-right: 25px;
  padding-top: 2px;
  border-radius: 3px;
}

.flex {
  display: flex;

  span {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.Project {
  width: 100%;
  height: 100%;
  // padding-top: 20px;

  // 主体内容
  .projectContent {
    box-sizing: border-box;
    // padding: 0 10px;
    height: 100%;
    flex: 1;

    .AllBox {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: flex-start;
      height: calc(100% - 40px);
      overflow-y: auto; // padding-top: 5px;



      .proAddCard {
        background-color: rgba(211, 211, 211, 0.2);
        padding: 0;
        position: relative;
        width: 220px;
        height: 177px;
        margin: 5px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.6);

        &:hover {
          color: rgba(0, 0, 0, 1);
          background-color: rgba(211, 211, 211, 0.8);
          box-shadow: 5px 5px 5px rgb(38 38 38 / 50%),
            5px 5px 10px rgb(38 38 38 / 30%);
        }

        .proAddImg {
          width: 220px;
          height: 100px;
          display: block;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        .proAddTitle {
          display: block;
          text-align: center;
          line-height: 77px;
          font-size: 22px;
          font-weight: bold;
          letter-spacing: 5px;
        }
      }

      .proListCard {
        padding: 0;
        position: relative;
        width: 220px;
        height: 177px;
        margin: 5px;

        &:hover {
          box-shadow: 5px 5px 5px rgb(38 38 38 / 50%),
            5px 5px 10px rgb(38 38 38 / 30%);

          .delIcon {
            opacity: 1;
          }
        }

        .proListImg {
          width: 220px;
          height: 100px;
          display: block;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        .taskType {
          display: block;
          padding: 0 5px;
          background: rgba(255, 0, 0, 0.6);
          height: 25px;
          line-height: 22px;
          text-align: center;
          font-size: 12px;
          font-weight: bold;
          border-radius: 0 0 10% 0;
          transition: all 100ms linear 0s;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1000;
          color: #ffffff;
        }

        .delIcon {
          display: block;
          background: rgba(255, 0, 0, 0.6);
          width: 30px;
          height: 25px;
          line-height: 22px;
          text-align: center;
          font-size: 20px;
          border-radius: 0 0 0 10%;
          opacity: 0;
          transition: all 100ms linear 0s;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1000;
          color: white;

          &:hover {
            background: rgba(255, 0, 0, 1);
          }
        }

        .taskStatus {
          display: block;
          background: rgba(0, 100, 255, 0.7);
          height: 19px;
          line-height: 19px;
          text-align: center;
          font-size: 12px;
          font-weight: bold;
          transition: all 100ms linear 0s;
          position: absolute;
          right: 0;
          top: 80px;
          padding: 0 5px;
          z-index: 1000;
          color: rgba(255, 255, 255, 1);
        }

        .taskStatus2 {
          display: block;
          background: #F79F2B;
          height: 19px;
          line-height: 19px;
          text-align: center;
          font-size: 12px;
          font-weight: bold;
          transition: all 100ms linear 0s;
          position: absolute;
          left: 0;
          top: 80px;
          padding: 0 5px;
          z-index: 1000;
          color: rgba(255, 255, 255, 1);
        }

        .clearfix:before,
        .clearfix:after {
          display: table;
          content: "";
        }

        .clearfix:after {
          clear: both;
        }

        .taskTitle {
          flex: 1;
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 13px;
          font-weight: bold;
          //color: #007fff;
        }

        .probottom {
          padding: 10px;

          .footerleft {
            float: left;
            font-size: 12px;
            color: #999;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 90px;
          }

          .footerright {
            float: right;
            font-size: 12px;
            color: #999;
          }

          .probutton {
            padding: 0;
            font-size: 12px;
            float: right;
          }
        }
      }
    }
  }
}

.list-img {
  width: 100%;
  height: 80px;
}

.list-item {


  &:hover {
    color: #d1100b;
  }
}

.additem {
  cursor: pointer;

  .el-divider {
    margin: 0;
  }

  :hover {
    color: #d1100b;
  }
}

.el-icon-delete {
  &:hover {
    color: red !important;
  }
}

:deep .el-tabs__content {
  overflow: visible;
}

.style-but {
  width: 40px;
  height: 40px;
  color: #ccc;
  font-size: 16px;
  padding: 0;
  border-radius: 40px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background: #f0f0f0;
    border-radius: 10px;
  }
}

.tabsType {
  display: block;
  padding: 0 5px;
  background: rgba(255, 0, 0, 0.6);
  height: 25px;
  line-height: 22px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  border-radius: 0 0 10% 0;
  transition: all 100ms linear 0s;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 88;
  color: #ffffff;
}

.tabsStatus {
  display: block;
  background: rgba(0, 100, 255, 0.7);
  height: 19px;
  line-height: 19px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  transition: all 100ms linear 0s;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0 5px;
  z-index: 88;
  color: rgba(255, 255, 255, 1);
}

.tabsStatus2 {
  display: block;
  background: #F79F2B;
  height: 19px;
  line-height: 19px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  transition: all 100ms linear 0s;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0 5px;
  z-index: 88;
  color: rgba(255, 255, 255, 1);
}

::v-deep .el-tabs__item {
  font-weight: bold;
  font-size: 16px;
}

::v-deep .el-tabs__item.is-active {
  color: #000;
}

::v-deep .el-tabs__content {
  height: calc(100% - 50px);
}

::v-deep .el-pagination {
  display: flex;
  height: 30px;
  margin-top: 10px;
  justify-content: right;


}

.el-divider {
  margin: 0;
}
</style>
